import React from 'react';
import { Logger } from '../../../utils/Logger';
import ChromacityGraphActiveBins from '../chromacity-graph-active-bins/ChromacityGraphActiveBins';
import ChromacityGraphTargetPolygon from '../chromacity-graph-target-polygon/ChromacityGraphTargetPolygon';
import './ChromacityGraph.scss';

class ChromacityGraph extends React.Component<any, any> {

  static readonly BACKGROUND = 'white';
  static readonly PADDING_BOTTOM = 50;
  static readonly PADDING_LEFT = 50;
  static readonly PADDING_TOP = 50;
  static readonly PADDING_RIGHT = 50;
  static readonly SCALES_FONT = '12px Arial';
  static readonly SCALES_FONT_COLOR = 'gray';
  static readonly X_SCALE_LINES = 9;
  static readonly Y_SCALE_LINES = 9;
  static readonly TARGET_POLIGON_COLOR = 'rgba(0, 0, 155, 1)';
  static readonly TARGET_POLIGON_INIT_WIDTH_PERCENTAGE = 20;
  static readonly TO_FIXED_PLACES = 4;

  state: any = {
    propsString: '',
    mounted: false,
    graphInfo: null,
    acesSetup: {
      x: {
        min: 0,
        max: 1
      },
      y: {
        min: 0,
        max: 1
      }
    },

    chromacityPolygons: [],
    customPolygons: [],
    
    targetPolygon: [],
    binningTarget: null,
    targetPolygonReal: [],
    targetPolygonEdit: false,
    targetPolygonEditSelected: null,
    activeBins: {},
    innerBins: {},
    unsavedActionTargetPolygon : false,
    unsavedActionBins : false,
  }

  private containerRef: React.RefObject<any>;
  private canvasRef: React.RefObject<any>;


  constructor (props: any) {
    Logger.debug('ChromacityGraph component init.');
    super(props);   

    this.containerRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    Logger.debug('ChromacityGraph component mount.');
    this.setState({executed: true});
  }

  componentDidUpdate() {
    Logger.debug('ChromacityGraph component update.');
    
    const propsString = JSON.stringify(this.props);
    if(this.state.propsString !== propsString) {

      let chromaticity = this.props.chromaticity;
      let targetpolygon: any = [];
      let targetpolygonRow: any = this.props?.channel?.targetPolygons;
      if (targetpolygonRow?.length) {
        // dots represent ellipse points from high y clockwise
        targetpolygon = [
          {
            x: targetpolygonRow[0]['leftTopX'],
            y: targetpolygonRow[0]['leftTopY'],
          },
          {
            x: targetpolygonRow[0]['rightTopX'],
            y: targetpolygonRow[0]['rightTopY'],
          },
          {
            x: targetpolygonRow[0]['rightBottomX'],
            y: targetpolygonRow[0]['rightBottomY'],
          },
          {
            x: targetpolygonRow[0]['leftBottomX'],
            y: targetpolygonRow[0]['leftBottomY'],
          }
        ];
      }
      this.setState({propsString});
      this.load(chromaticity, targetpolygon);
    
    } else {
      this.draw();
    }
  }

  load(chromaticity: any, targetpolygon: any) {
    Logger.debug('ChromacityGraph load data.');

    // set init
    const acesSetup = {
      x: {
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER
      },
      y: {
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER
      }
    };

    const chromacityPolygons = [];
    if (chromaticity.length) {
      for (const it of chromaticity) {


        let A = {x: 0, y: 0};
        let B = {x: 0, y: 0};
        let C = {x: 0, y: 0};
        let D = {x: 0, y: 0};
        let type = null;
        let name = it?.name;
        let cnt = 0;
        const xVals = [];
        const yVals = [];
        if (it?.values.length) {
          for (const v of it?.values) {

            if (v?.name === 'type') {
              type = v?.value;
            }

            if (v?.name === 'x1') {
              A.x = v?.value;
              cnt++;
              xVals.push(v?.value);
            } else if (v?.name === 'x2') {
              B.x = v?.value;
              cnt++;
              xVals.push(v?.value);
            } else if (v?.name === 'x3') {
              C.x = v?.value;
              cnt++;
              xVals.push(v?.value);
            } else if (v?.name === 'x4') {
              D.x = v?.value;
              cnt++;
              xVals.push(v?.value);
            } else if (v?.name === 'y1') {
              A.y = v?.value;
              cnt++;
              yVals.push(v?.value);
            } else if (v?.name === 'y2') {
              B.y = v?.value;
              cnt++;
              yVals.push(v?.value);
            } else if (v?.name === 'y3') {
              C.y = v?.value;
              cnt++;
              yVals.push(v?.value);
            } else if (v?.name === 'y4') {
              D.y = v?.value;
              cnt++;
              yVals.push(v?.value);
            }
          }
        }

        if (type === 'rectangle' && cnt === 8) {// all 8 coordinates
          const maxX = Math.max(...xVals);
          const minX = Math.min(...xVals);
          const maxY = Math.max(...yVals);
          const minY = Math.min(...yVals);
          acesSetup.y.max = maxY > acesSetup.y.max ? maxY : acesSetup.y.max;
          acesSetup.y.min = minY < acesSetup.y.min ? minY : acesSetup.y.min;
          acesSetup.x.max = maxX > acesSetup.x.max ? maxX : acesSetup.x.max;
          acesSetup.x.min = minX < acesSetup.x.min ? minX : acesSetup.x.min;
          

          chromacityPolygons.push({
            id: it?.id,
            title: name,
            fillColor: 'rgba(150, 150, 150, 0.1)', //it?.id ===6 ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
            lineColor: 'black',
            lineWidth: 1,
            rect: [A,B,C,D],
            ellipse: null
          });
        } else if (type === 'ellipse') {
          let minX = A.x - B.x;
          let acesSetupXmin = minX < acesSetup.x.min ? minX : acesSetup.x.min;
          let minY = A.y - B.y;
          let acesSetupYmin = minY < acesSetup.y.min ? minY : acesSetup.y.min;

          let maxX = A.x + B.x;
          let acesSetupXmax = maxX > acesSetup.x.max ? maxX : acesSetup.x.max;
          let maxY = A.y + B.y;
          let acesSetupYmax = maxY > acesSetup.y.max ? maxY : acesSetup.y.max;

          // sonce they could rotate take bigger
          acesSetupXmax > acesSetupYmax ? acesSetupYmax = acesSetupXmax : acesSetupXmax = acesSetupYmax;
          acesSetupXmin > acesSetupYmin ? acesSetupYmin = acesSetupXmin : acesSetupXmin = acesSetupYmin;

          if (acesSetup.y.max < acesSetupYmax) {
            acesSetup.y.max = acesSetupYmax;
          }
          if (acesSetup.x.max < acesSetupXmax) {
            acesSetup.x.max = acesSetupXmax;
          }

          if (acesSetup.y.min > acesSetupYmin) {
            acesSetup.y.min = acesSetupYmin;
          }
          if (acesSetup.x.min > acesSetupXmin) {
            acesSetup.x.min = acesSetupXmin;
          }

          chromacityPolygons.push({
            id: it?.id,
            title: name,
            fillColor: 'rgba(150, 150, 150, 0.1)', //it?.id ===6 ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
            lineColor: 'black',
            lineWidth: 1,
            rect: null,
            ellipse: [A, B, {x:0, y:0, angle:C.x}]
          });
        }
      }
    }

    this.applyLoaded(chromacityPolygons, acesSetup, targetpolygon);
    
  }

  applyLoaded(chromacityPolygons: any, acesSetup: any, targetpolygon: any) {

    // add empty space 10%
    acesSetup.y.max = acesSetup.y.max + 0.1*(acesSetup.y.max - acesSetup.y.min);
    acesSetup.y.min = acesSetup.y.min - 0.1*(acesSetup.y.max - acesSetup.y.min);
    acesSetup.x.max = acesSetup.x.max + 0.1*(acesSetup.x.max - acesSetup.x.min);
    acesSetup.x.min = acesSetup.x.min - 0.1*(acesSetup.x.max - acesSetup.x.min);

    const xdiff = acesSetup.x.max - acesSetup.x.min;
    const ydiff = acesSetup.y.max - acesSetup.y.min;
    const maxDiff = Math.max(xdiff, ydiff);
    if (xdiff < maxDiff) {
        let centerX = (acesSetup.x.max + acesSetup.x.min) / 2;
        acesSetup.x.min = centerX - maxDiff / 2;
        acesSetup.x.max = centerX + maxDiff / 2;
    }else if (ydiff < maxDiff) {
        let centerY = (acesSetup.y.max + acesSetup.y.min) / 2;
        acesSetup.y.min = centerY - maxDiff / 2;
        acesSetup.y.max = centerY + maxDiff / 2;
    }

    // no negative graph values
    acesSetup.x.min  = acesSetup.x.min < 0 ? 0 : acesSetup.x.min
    acesSetup.y.min  = acesSetup.y.min < 0 ? 0 : acesSetup.y.min

    for (const it of chromacityPolygons) {
      if (it?.rect) {
        for (const p of it?.rect) {
          const point = this.scaleToGraphAces(acesSetup, p.x, p.y);
          p.x = point.x
          p.y = point.y;
        }
      } else if (it?.ellipse?.length >= 3) {
        let point = this.scaleToGraphAces(acesSetup,  it?.ellipse[0].x,  it?.ellipse[0].y);
        it.ellipse[0].x = point.x;
        it.ellipse[0].y = point.y;
        point = this.scaleToGraphAces(acesSetup,  it?.ellipse[1].x,  it?.ellipse[1].y);

        const graphInfo: any = this.getGraphInfo();
        
        it.ellipse[1].x =  it.ellipse[1].x * graphInfo.width / (acesSetup.x.max - acesSetup.x.min);
        it.ellipse[1].y = it.ellipse[1].y* graphInfo.height / (acesSetup.y.max - acesSetup.y.min);

      }
    }
    
    this.setTargetPolygonRealValues(acesSetup, targetpolygon);
    this.setState({chromacityPolygons, acesSetup});

  }

  scaleToGraphAces(acesSetup: any, x: any, y: any) {
    acesSetup = acesSetup ? acesSetup : this.state.acesSetup;
    const yrange = acesSetup.y.max - acesSetup.y.min;
    const xrange = acesSetup.x.max - acesSetup.x.min;

    const graphInfo: any = this.getGraphInfo(); 
    return {
      x: ((x - acesSetup.x.min) * 100 / xrange) * graphInfo.width / 100 + ChromacityGraph.PADDING_LEFT,
      y: graphInfo.height - ((y - acesSetup.y.min) * 100 / yrange) * graphInfo.height / 100 + ChromacityGraph.PADDING_TOP
    }
  }
  
  scaleToRealAces(acesSetup: any, x: any, y: any) {
    acesSetup = acesSetup ? acesSetup : this.state.acesSetup;
    const yrange = acesSetup.y.max - acesSetup.y.min;
    const xrange = acesSetup.x.max - acesSetup.x.min;
 
    const graphInfo: any = this.getGraphInfo(); 
    return {
      x: (x - ChromacityGraph.PADDING_LEFT) * xrange / graphInfo.width + acesSetup.x.min,
      y: yrange - ((y - ChromacityGraph.PADDING_TOP) * yrange / graphInfo.height) + acesSetup.y.min
    }
  }

  getGraphInfo() {
    var ctx = this.canvasRef?.current?.getContext("2d");
    if (!ctx) {
      return;
    }

    return {
      width: this.canvasRef?.current?.width - ChromacityGraph.PADDING_LEFT - ChromacityGraph.PADDING_RIGHT,
      height: this.canvasRef?.current?.height - ChromacityGraph.PADDING_BOTTOM - ChromacityGraph.PADDING_TOP,
      xLineZoneWidth: (this.canvasRef?.current?.width - ChromacityGraph.PADDING_LEFT - ChromacityGraph.PADDING_RIGHT)/(ChromacityGraph.X_SCALE_LINES - 1),
      yLineZoneWidth: (this.canvasRef?.current?.height - ChromacityGraph.PADDING_BOTTOM - ChromacityGraph.PADDING_TOP)/(ChromacityGraph.Y_SCALE_LINES - 1)
    }
  }

  drawGraphField() {
    var ctx = this.canvasRef?.current?.getContext("2d");
    if (!ctx) {
      return;
    }

    const graphInfo: any = this.getGraphInfo(); 

    // clear all
    ctx.fillStyle = ChromacityGraph.BACKGROUND;
    ctx.fillRect(0, 0, this.canvasRef?.current?.width, this.canvasRef?.current?.height);
    
    // draw aces
    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.setLineDash([3, 0]);
    ctx.lineWidth = 1;
    ctx.moveTo(ChromacityGraph.PADDING_LEFT, ChromacityGraph.PADDING_TOP);
    ctx.lineTo(ChromacityGraph.PADDING_LEFT, graphInfo.height + ChromacityGraph.PADDING_TOP);
    ctx.lineTo(graphInfo.width + ChromacityGraph.PADDING_LEFT, graphInfo.height + ChromacityGraph.PADDING_TOP);
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.setLineDash([3, 3]);
    ctx.lineWidth = 0.2;
    
    // draw vertical lines
    for (let i = 1; i < ChromacityGraph.X_SCALE_LINES; i++) {
      ctx.moveTo(i * graphInfo.xLineZoneWidth + ChromacityGraph.PADDING_LEFT, ChromacityGraph.PADDING_TOP);
      ctx.lineTo(i * graphInfo.xLineZoneWidth + ChromacityGraph.PADDING_LEFT, graphInfo.height + ChromacityGraph.PADDING_TOP);
    }

    // draw horizontal lines
    for (let i = 0; i < ChromacityGraph.Y_SCALE_LINES - 1; i++) {
      ctx.moveTo(ChromacityGraph.PADDING_LEFT, i * graphInfo.yLineZoneWidth + ChromacityGraph.PADDING_TOP);
      ctx.lineTo(graphInfo.width + ChromacityGraph.PADDING_LEFT, i * graphInfo.yLineZoneWidth+ ChromacityGraph.PADDING_TOP);
    }

    ctx.stroke();
    ctx.closePath();
  }

  drawChromacityPolygons() {
    this.drawPolygons(this.state.chromacityPolygons);
    this.drawEllipses(this.state.chromacityPolygons);
  }

  drawCustomPolygons() {
    this.drawPolygons(this.state.customPolygons);
  }
  
  drawEllipses(ellipses: any) {
    var ctx = this.canvasRef?.current?.getContext("2d");
    if (!ctx) {
      return;
    }

    for (const pl of ellipses) {

      const p: any = pl;
      if (!p.ellipse || p.ellipse.length < 3) {
        continue;
      }
      
      ctx.beginPath();
      const angle = p.ellipse[2].angle * (Math.PI/180);
      ctx.ellipse(p.ellipse[0].x, p.ellipse[0].y, p.ellipse[1].x, p.ellipse[1].y, angle, 0, 2 * Math.PI);
      if (p.fillColor) {
        // TODO change this is logic only for chromaticity polygon and active bins logic
        if (this.state.activeBins[p?.id] && this.state.innerBins[p?.id]) {
          ctx.fillStyle = this.state.activeBins[p?.id] ? 'rgba(102, 225, 225, 0.3)' : p?.fillColor;
        } else {
          ctx.fillStyle = this.state.activeBins[p?.id] ? 'rgba(0, 255, 0, 0.1)' : p?.fillColor;
        }
        ctx.fill();
      }
      ctx.setLineDash([5, 5]);
      ctx.strokeStyle = 'black';

      if (p.lineColor) {
        ctx.strokeStyle = p.lineColor;
      }
      if (p.lineWidth) {
        ctx.lineWidth = p.lineWidth;  
      }  
      ctx.stroke();
    
      if (p.title) {

        // draw dots
        ctx.setLineDash([1, 0]);
        ctx.fillStyle = 'white';
        
        ctx.beginPath();
        ctx.arc(p.ellipse[0].x,p.ellipse[0].y, 3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
          
          ctx.font = '14px Arial';
          ctx.fillStyle = 'gray';
          ctx.textAlign = "center";
          ctx.textBaseline = 'middle';
          ctx.fillText(p.title, p.ellipse[0].x, p.ellipse[0].y + 15);
      }
    }
  }

  drawPolygons(polygons: any) {
    var ctx = this.canvasRef?.current?.getContext("2d");
    if (!ctx) {
      return;
    }
    

    for (const pl of polygons) {

      const p: any = pl;
      if (!p.rect || p.rect.length !== 4) {
        continue;
      }

      // draw lines
      ctx.beginPath();
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;
      ctx.setLineDash([5, 5]);
      
      if (p.lineColor) {
        ctx.strokeStyle = p.lineColor;
      }
      if (p.lineWidth) {
        ctx.lineWidth = p.lineWidth;  
      }   

      ctx.moveTo(p.rect[0].x,p.rect[0].y);
      ctx.lineTo(p.rect[1].x,p.rect[1].y);
      ctx.lineTo(p.rect[2].x,p.rect[2].y);
      ctx.lineTo(p.rect[3].x,p.rect[3].y);
      ctx.lineTo(p.rect[0].x,p.rect[0].y);

        
      if (p.fillColor) {
        // TODO change this is logic only for chromaticity polygon and active bins logic
        if (this.state.activeBins[p?.id] && this.state.innerBins[p?.id]) {
          ctx.fillStyle = 'rgba(102, 225, 225, 0.3)';
        } else {
          ctx.fillStyle = this.state.activeBins[p?.id] ? 'rgba(0, 255, 0, 0.1)' : p?.fillColor;
        }
        ctx.fill();
      }
      ctx.stroke();
      ctx.closePath();

      // draw dots
      ctx.setLineDash([1, 0]);
      ctx.fillStyle = 'white';
      
      ctx.beginPath();
      ctx.arc(p.rect[0].x,p.rect[0].y, 3, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
      
      ctx.beginPath();
      ctx.arc(p.rect[1].x,p.rect[1].y, 3, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(p.rect[2].x,p.rect[2].y, 3, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      ctx.arc(p.rect[3].x,p.rect[3].y, 3, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();

    
      if (p.title) {
        let x = (
          Math.min(p.rect[0].x, p.rect[1].x, p.rect[2].x, p.rect[3].x) + 
          Math.max(p.rect[0].x, p.rect[1].x, p.rect[2].x, p.rect[3].x)) / 2;
        let y = (
          Math.min(p.rect[0].y, p.rect[1].y, p.rect[2].y, p.rect[3].y) + 
          Math.max(p.rect[0].y, p.rect[1].y, p.rect[2].y, p.rect[3].y)) / 2;
          
          ctx.font = '14px Arial';
          ctx.fillStyle = 'gray';
          ctx.textAlign = "center";
          ctx.textBaseline = 'middle';
          ctx.fillText(p.title, x, y);
      }
    }
    
  }


  drawScales() {
    var ctx = this.canvasRef?.current?.getContext("2d");
    if (!ctx) {
      return;
    }
  
    const graphInfo: any = this.getGraphInfo(); 
  
    // clear scales space
    ctx.fillStyle = '#fefefe';
    ctx.fillRect(0, 0, ChromacityGraph.PADDING_LEFT, this.canvasRef?.current?.height + ChromacityGraph.PADDING_TOP);
    ctx.fillRect(0, graphInfo.height + ChromacityGraph.PADDING_TOP, this.canvasRef?.current?.width, ChromacityGraph.PADDING_BOTTOM);
  
    // draw values
    ctx.font = ChromacityGraph.SCALES_FONT;
    ctx.fillStyle = ChromacityGraph.SCALES_FONT_COLOR;
    ctx.textAlign = "right";
    ctx.textBaseline = 'middle'
  
    const diffX = (this.state.acesSetup.y.max - this.state.acesSetup.y.min) / (ChromacityGraph.Y_SCALE_LINES-1);
    for (let i = 0; i <= ChromacityGraph.Y_SCALE_LINES; i = i + 2) { // every second
      const v = String((this.state.acesSetup.y.max - i * diffX).toFixed(ChromacityGraph.TO_FIXED_PLACES));      
      ctx.fillText(v, ChromacityGraph.PADDING_LEFT - 5, i * graphInfo.yLineZoneWidth + ChromacityGraph.PADDING_TOP, ChromacityGraph.PADDING_LEFT);
    }
  
    ctx.textBaseline = 'bottom'
    ctx.textAlign = "center";
  
      // TODO move magics to consts
    const diffY = (this.state.acesSetup.x.max - this.state.acesSetup.x.min) / (ChromacityGraph.X_SCALE_LINES-1);
    for (let i = 0; i < ChromacityGraph.X_SCALE_LINES; i = i + 2) { // every second
      const v = String((this.state.acesSetup.x.min + i * diffY).toFixed(ChromacityGraph.TO_FIXED_PLACES));      
      ctx.fillText(v, i * graphInfo.xLineZoneWidth + ChromacityGraph.PADDING_LEFT, graphInfo.height + ChromacityGraph.PADDING_TOP+ 20);
    }
  }

    
  drawTargetPolygon () {
    var ctx = this.canvasRef?.current?.getContext("2d");
    if (!ctx || this.state?.targetPolygon?.length === 0) {
      return;
    }

    const centerX = (this.state?.targetPolygon[0].x + this.state?.targetPolygon[2].x) / 2;
    const centerY = (this.state?.targetPolygon[1].y + this.state?.targetPolygon[3].y) / 2;
    const height = Math.sqrt(Math.pow(this.state?.targetPolygon[0].x - this.state?.targetPolygon[2].x, 2) + Math.pow(this.state?.targetPolygon[0].y - this.state?.targetPolygon[2].y, 2));
    const width = Math.sqrt(Math.pow(this.state?.targetPolygon[1].x - this.state?.targetPolygon[3].x, 2) + Math.pow(this.state?.targetPolygon[1].y - this.state?.targetPolygon[3].y, 2));
    const angle = Math.atan2(this.state?.targetPolygon[1].y - centerY, this.state?.targetPolygon[1].x - centerX);

    if (height <= 0 || width <= 0) {
      return;
    }

    // draw ellipse
    ctx.beginPath();
    ctx.strokeStyle = ChromacityGraph.TARGET_POLIGON_COLOR;
    ctx.lineWidth = 2;
    ctx.setLineDash([5, 5]);
    ctx.ellipse(centerX, centerY, width/2, height/2, angle, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(0, 0, 155, 0.1)";
    ctx.fill();
    ctx.stroke();
    ctx.closePath();

    // draw dots
    if (this.state?.targetPolygonEdit && this.state?.targetPolygon.length > 0) {
      for (let i = 0; i < this.state?.targetPolygon.length; i++) {

        ctx.beginPath();
        ctx.setLineDash([1, 0]);
        ctx.arc(this.state?.targetPolygon[i].x, this.state?.targetPolygon[i].y, 15, 0, 2 * Math.PI);
        ctx.fillStyle = 'rgba(0, 0, 255, 0.4)';
        ctx.strokeStyle = 'rgba(188, 0, 0, 0)';
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
    
        ctx.beginPath();
        ctx.setLineDash([1, 0]);
        ctx.arc(this.state?.targetPolygon[i].x, this.state?.targetPolygon[i].y, 3, 0, 2 * Math.PI);
        ctx.fillStyle = 'white';
        ctx.strokeStyle = ChromacityGraph.TARGET_POLIGON_COLOR;
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }


      //center
      ctx.beginPath();
      ctx.setLineDash([1, 0]);
      ctx.arc(centerX, centerY, 15, 0, 2 * Math.PI);
      ctx.fillStyle = 'rgba(0, 0, 255, 0.4)';
      ctx.strokeStyle = 'rgba(188, 0, 0, 0)';
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
    }    

    //center
    ctx.beginPath();
    ctx.setLineDash([1, 0]);
    ctx.arc(centerX, centerY, 3, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.strokeStyle = ChromacityGraph.TARGET_POLIGON_COLOR;
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
  }

  setTargetPolygonRealValues(acesSetup: any, targetPolygonReal: any) {
    const targetPolygon: any = [];
    let binningTarget = null;

    if (targetPolygonReal.length === 4) {

      const top = this.scaleToGraphAces(acesSetup, targetPolygonReal[0].x, targetPolygonReal[0].y);
      const left = this.scaleToGraphAces(acesSetup, targetPolygonReal[3].x, targetPolygonReal[3].y);
      const right = this.scaleToGraphAces(acesSetup, targetPolygonReal[1].x, targetPolygonReal[1].y);
      const bottom = this.scaleToGraphAces(acesSetup, targetPolygonReal[2].x, targetPolygonReal[2].y);

      const centerX = (top.x + bottom.x) / 2;
      const centerY = (right.y + left.y) / 2;
      const height = Math.sqrt(Math.pow(top.x - bottom.x, 2) + Math.pow(top.y - bottom.y, 2));
      const width = Math.sqrt(Math.pow(right.x - left.x, 2) + Math.pow(right.y - left.y, 2));
      const angleEllipse = Math.atan2(right.y - centerY, right.x - centerX);

      const polygon = [
        { x: centerX, y: centerY - height / 2 },  
        { x: centerX + width / 2, y: centerY },
        { x: centerX, y: centerY + height / 2 },
        { x: centerX - width / 2, y: centerY },
      ];
      polygon.map((point: any) => {
        targetPolygon.push(this.rotatePoint(point.x, point.y, angleEllipse, centerX, centerY));
      });
    }

    if (this.props.binningTarget) {
      binningTarget = [];
      for (const it of this.props.binningTarget) {
        binningTarget.push(this.scaleToGraphAces(acesSetup, it.x, it.y));
      }
    }
    
    this.setState({targetPolygon, targetPolygonReal, binningTarget});
  }

  setTargetPolygonGraphValues(targetPolygon: any) {
    let targetPolygonReal = [];
    for (const it of targetPolygon) {
      const p = this.scaleToRealAces(this.state.accesSetup, it.x, it.y);
      targetPolygonReal.push({
        x: +(p.x).toFixed(ChromacityGraph.TO_FIXED_PLACES),
        y: +(p.y).toFixed(ChromacityGraph.TO_FIXED_PLACES)
      });
    }

    const cx = (targetPolygonReal[0]?.x + targetPolygonReal[2]?.x) / 2;
    const cy = (targetPolygonReal[1]?.y + targetPolygonReal[3]?.y) / 2;
    const height = Math.sqrt(Math.pow(targetPolygonReal[0].x - targetPolygonReal[2].x, 2) + Math.pow(targetPolygonReal[0].y - targetPolygonReal[2].y, 2));
    const width = Math.sqrt(Math.pow(targetPolygonReal[1].x - targetPolygonReal[3].x, 2) + Math.pow(targetPolygonReal[1].y - targetPolygonReal[3].y, 2));
    const angle = Math.atan2(targetPolygonReal[1].y - cy, targetPolygonReal[1].x - cx);
    const polygon = [{ x: cx, y: cy - height / 2 }, { x: cx + width / 2, y: cy },{ x: cx, y: cy + height / 2 },{ x: cx - width / 2, y: cy },];
    
    if (angle !== 0) {
      targetPolygonReal = [];
      targetPolygonReal = polygon.map((point: any) => {
        const p = this.rotatePoint(point.x, point.y, angle, cx, cy);
        console.log("(" + +(p.x).toFixed(ChromacityGraph.TO_FIXED_PLACES) + "," + +(p.y).toFixed(ChromacityGraph.TO_FIXED_PLACES) + ")");
        return p;
      });
    }
    this.setState({targetPolygon, targetPolygonReal});
  }

  drawBinningTarget() {
    if (this.state.binningTarget) {
      for (const it of this.state.binningTarget) {
        var ctx = this.canvasRef?.current?.getContext("2d");
        if (!ctx || this.state?.targetPolygon?.length === 0) {
          continue;
        }
        ctx.beginPath();
        ctx.setLineDash([1, 0]);
        
        ctx.arc(it.x, it.y, 2, 0, 2 * Math.PI);
        ctx.fillStyle = 'rgba(50, 0, 0, 1)';
        ctx.strokeStyle = 'rgba(50, 0, 0, 1)';
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
  
        ctx.arc(it.x, it.y, 15, 0, 2 * Math.PI);
        ctx.fillStyle = 'rgba(255, 0, 0, 0.4)';
        ctx.strokeStyle = 'rgba(188, 0, 0, 0)';
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }
    }
  }

  draw() {
    if (this.state.chromacityPolygons.length === 0) {
      return null;
    }
    this.drawGraphField();
    this.drawChromacityPolygons();
    this.drawTargetPolygon();
    this.drawBinningTarget();
    this.drawScales();
  }

  setActiveBin(activeBins: any) {
    this.setState({activeBins});
    this.draw();
    if(this.props?.activeBins) {
      this.props?.activeBins(activeBins);
    }    
  }

  setInnerBin(innerBins: any) {
    this.setState({innerBins});
    this.draw();
    if(this.props?.innerBins) {
      this.props?.innerBins(innerBins);
    }    
  }

  private isOutOfGraphPosition(x: any, y: any) {
    return (x < ChromacityGraph.PADDING_LEFT || x > this.canvasRef?.current?.width - ChromacityGraph.PADDING_RIGHT ||
      y < ChromacityGraph.PADDING_TOP || y > this.canvasRef?.current?.height - ChromacityGraph.PADDING_BOTTOM);
  }

  private checkIfPositive(targetPolygon: any) {
    if (targetPolygon[0]?.x && targetPolygon[1]?.x && targetPolygon[0]?.x + 10 >= targetPolygon[1]?.x) {
      return false;
    }
    if (targetPolygon[0]?.y && targetPolygon[1]?.y && targetPolygon[0]?.y + 10 >= targetPolygon[1]?.y) {
      return false;
    }
    return true;
  }

  onCanvasMouseDown(e: any) {
    const r = this.canvasRef?.current?.getBoundingClientRect();
    const x = e.clientX - r.x;
    const y = e.clientY - r.y;

    if (this.isOutOfGraphPosition(x, y)) {
      return;
    }

    const targetPolygon = JSON.parse(JSON.stringify(this.state?.targetPolygon));
    if (this.state?.targetPolygonEdit) {      
      if (targetPolygon.length === 0) {
        targetPolygon.push({ x : x, y: y - this.canvasRef?.current?.height * ChromacityGraph.TARGET_POLIGON_INIT_WIDTH_PERCENTAGE / 100 });  
        targetPolygon.push({ x : x + this.canvasRef?.current?.width * ChromacityGraph.TARGET_POLIGON_INIT_WIDTH_PERCENTAGE / 100, y  });
        targetPolygon.push({ x : x, y: y + this.canvasRef?.current?.height * ChromacityGraph.TARGET_POLIGON_INIT_WIDTH_PERCENTAGE / 100 });
        targetPolygon.push({ x : x - this.canvasRef?.current?.width * ChromacityGraph.TARGET_POLIGON_INIT_WIDTH_PERCENTAGE / 100, y  }); 

        if (!this.checkIfPositive(targetPolygon)) {
          return;
        }
      } else {
        const centerX = (this.state?.targetPolygon[0].x + this.state?.targetPolygon[2].x) / 2;
        const centerY = (this.state?.targetPolygon[1].y + this.state?.targetPolygon[3].y) / 2;

        let s: any = null;
        for (let i = 0; i < targetPolygon.length; i++) {
          if (Math.abs(targetPolygon[i]?.x - x) <= 10 && Math.abs(targetPolygon[i]?.y - y) <= 10) {
            s = i;
            break;
          }
        }

        // center
        if (s === null && this.state?.targetPolygon.length > 0 && 
          Math.abs(centerX - x) <= 10 && Math.abs(centerY - y) <= 10) {
            s = targetPolygon.length;
        }
        
        // if (!this.checkIfPositive(targetPolygon)) {
        //   return;
        // }
        this.setState({targetPolygonEditSelected : s});
      }
      
      console.log(targetPolygon[0].x, targetPolygon[1].x, targetPolygon[2].x, targetPolygon[3].x)


      this.setTargetPolygonGraphValues(targetPolygon);
    }
  }

  onCanvasMouseMove(e: any) {
    const r = this.canvasRef?.current?.getBoundingClientRect();
    const x: number = e.clientX - r.x;
    const y: number = e.clientY - r.y;

    if (this.isOutOfGraphPosition(x, y)) {
      return;
    }
    
    if (this.state?.targetPolygonEdit && this.state?.targetPolygonEditSelected !== null) {
        const targetPolygon = this.setTargetPolygon(x, y);

        // if (!this.checkIfPositive(targetPolygon)) {
        //     return;
        // }
        
        this.setTargetPolygonGraphValues(targetPolygon);
    }
  }

  onCanvasMouseUp(e: any) {
    const r = this.canvasRef?.current?.getBoundingClientRect();
    const x: number = e.clientX - r.x;
    const y: number = e.clientY - r.y;

    if (this.isOutOfGraphPosition(x, y)) {
      return;
    }

  this.setState({targetPolygonEditSelected : null});
  }

  setTargetPolygon(x: number, y: number) {
    const targetPolygonNew = [];
    let xdiff = 0;
    let ydiff = 0;

    const centerX = (this.state?.targetPolygon[0]?.x + this.state?.targetPolygon[2]?.x) / 2;
    const centerY = (this.state?.targetPolygon[1]?.y + this.state?.targetPolygon[3]?.y) / 2;

    if (this.state?.targetPolygonEditSelected < this.state?.targetPolygon.length) {

      const angleDiff = this.setAngle(centerX, centerY, x, y);

      xdiff = this.state?.targetPolygon[this.state?.targetPolygonEditSelected]?.x - x;
      ydiff = this.state?.targetPolygon[this.state?.targetPolygonEditSelected]?.y - y;

      for (let i = 0; i < this.state?.targetPolygon.length; i++) {
        if(i !== this.state?.targetPolygonEditSelected) {
          if (((i === 0 || i === 2) && (this.state?.targetPolygonEditSelected === 0 || this.state?.targetPolygonEditSelected === 2)) ||
              (i === 1 || i === 3) && (this.state?.targetPolygonEditSelected === 1 || this.state?.targetPolygonEditSelected === 3)) {
                targetPolygonNew.push({ x: this.state?.targetPolygon[i].x + xdiff, y: this.state?.targetPolygon[i].y + ydiff });          
          } else {
            const X = centerX + (this.state?.targetPolygon[i]?.x - centerX) * Math.cos(angleDiff) - (this.state?.targetPolygon[i]?.y - centerY) * Math.sin(angleDiff);
            const Y = centerY + (this.state?.targetPolygon[i]?.x - centerX) * Math.sin(angleDiff) + (this.state?.targetPolygon[i]?.y - centerY) * Math.cos(angleDiff);
            targetPolygonNew.push({x: +X.toFixed(ChromacityGraph.TO_FIXED_PLACES), y: +Y.toFixed(ChromacityGraph.TO_FIXED_PLACES)});
          }
        } else if (i === this.state?.targetPolygonEditSelected) {
            targetPolygonNew.push({x: +x.toFixed(ChromacityGraph.TO_FIXED_PLACES), y: +y.toFixed(ChromacityGraph.TO_FIXED_PLACES)});
        }
      }

      const height = Math.sqrt(Math.pow(targetPolygonNew[0].x - targetPolygonNew[2].x, 2) + Math.pow(targetPolygonNew[0].y - targetPolygonNew[2].y, 2));
      const width = Math.sqrt(Math.pow(targetPolygonNew[1].x - targetPolygonNew[3].x, 2) + Math.pow(targetPolygonNew[1].y - targetPolygonNew[3].y, 2));
      const angleEllipse = Math.atan2(targetPolygonNew[1].y - centerY, targetPolygonNew[1].x - centerX);

      const polygon = [
        { x: centerX, y: centerY - height / 2 },  
        { x: centerX + width / 2, y: centerY },
        { x: centerX, y: centerY + height / 2 },
        { x: centerX - width / 2, y: centerY },
      ];
      const rotatedPoly = polygon.map((point: any) => this.rotatePoint(point.x, point.y, angleEllipse, centerX, centerY));

      return rotatedPoly;
    } else if (this.state?.targetPolygonEditSelected === this.state?.targetPolygon.length) { // center
        xdiff = centerX - x;
        ydiff = centerY - y;

        targetPolygonNew.push({ x: +(this.state?.targetPolygon[0].x - xdiff).toFixed(ChromacityGraph.TO_FIXED_PLACES), y: +(this.state?.targetPolygon[0].y - ydiff).toFixed(ChromacityGraph.TO_FIXED_PLACES) });
        targetPolygonNew.push({ x: +(this.state?.targetPolygon[1].x - xdiff).toFixed(ChromacityGraph.TO_FIXED_PLACES), y: +(this.state?.targetPolygon[1].y - ydiff).toFixed(ChromacityGraph.TO_FIXED_PLACES) });
        targetPolygonNew.push({ x: +(this.state?.targetPolygon[2].x - xdiff).toFixed(ChromacityGraph.TO_FIXED_PLACES), y: +(this.state?.targetPolygon[2].y - ydiff).toFixed(ChromacityGraph.TO_FIXED_PLACES) });
        targetPolygonNew.push({ x: +(this.state?.targetPolygon[3].x - xdiff).toFixed(ChromacityGraph.TO_FIXED_PLACES), y: +(this.state?.targetPolygon[3].y - ydiff).toFixed(ChromacityGraph.TO_FIXED_PLACES) });

        return targetPolygonNew; 
    }
  }

  rotatePoint(px : any, py: any, angle: any, cx: any, cy: any) {
    let x = cx + (px - cx) * (Math.cos(angle)) - (py - cy) * (Math.sin(angle));
    let y = cy + (px - cx) * (Math.sin(angle)) + (py - cy) * (Math.cos(angle));
    
    return { x: x, y: y };
  }

  setAngle(cx: number, cy: number, valx: number, valy: number) {
    const x = this.state?.targetPolygon[this.state?.targetPolygonEditSelected].x;
    const y = this.state?.targetPolygon[this.state?.targetPolygonEditSelected].y;
    const angle = Math.atan2((valy - cy), (valx - cx)) - Math.atan2((y - cy), (x - cx));

    return angle;
  }

  render() {
    return (
      <div className='app-chromaticity-graph-wrapper'>
    
        <div className='app-chromaticity-graph' ref={this.containerRef}>
          {this.containerRef?.current?.offsetWidth && this.containerRef?.current?.offsetHeight && 
            <canvas 
              onMouseDown ={(e: any) => {this.onCanvasMouseDown(e)}} 
              onMouseUp ={(e: any) => {this.onCanvasMouseUp(e)}} 
              onMouseMove ={(e: any) => {this.onCanvasMouseMove(e)}} 
              ref={this.canvasRef} 
              width={this.containerRef.current.offsetWidth} 
              height={this.containerRef.current.offsetHeight}>
            </canvas>
          }
        </div>

        {!this.props.hideControls && <div className='app-chromaticity-graph-options pl5 pr5'>
          
          <div className='app-chromaticity-graph-target-polygon-label'>
            <div>
            <span className='app-chromaticity-graph-target-polygon-label-item-ta'><span></span>Target Area</span>
            <span className='app-chromaticity-graph-target-polygon-label-item-active ml15'><span></span>Active Bins</span>
            <span className='app-chromaticity-graph-target-polygon-label-item-inner ml15'><span></span>Inner Bins</span>
            </div>
          </div>

          <div>
            <ChromacityGraphTargetPolygon 
              channel={this.props.channel}
              polygon={this.state.targetPolygonReal}
              update={(e: any) => {this.setTargetPolygonRealValues(this.state.acesSetup, e)}}
              editEnabled={this.state.targetPolygonEdit}
              edit={(e: any) => {this.setState({targetPolygonEdit : e ?? !this.state?.targetPolygonEdit})}}
              unsavedAction={this.state.unsavedActionBins}
              unsavedActionUpdate={(e: any) => {this.setState({unsavedActionTargetPolygon : e})}}>
            </ChromacityGraphTargetPolygon>
          </div>


          <ChromacityGraphActiveBins
            chromaticity={this.props.chromaticity}
            set={(e: any) => {this.setActiveBin(e)}}
            setinner={(e: any) => {this.setInnerBin(e)}}
            product={this.props.product}
            channel={this.props.channel}
            unsavedAction={this.state.unsavedActionTargetPolygon}
            unsavedActionUpdate={(e: any) => {this.setState({unsavedActionBins : e})}}>
          </ChromacityGraphActiveBins>

          

        </div>}

      </div>
    );
  }

}

export default ChromacityGraph;


